// import http from '../http-common'
import { useUserStore } from '@/store/userStore'

export default {
  async removeCoupon(cartId) {
    try {
      const response = await useApi(`/api/carts/${cartId}/discount-code`, {
        method: 'DELETE',
        watch: false,
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async applyCoupon(cartId, code) {
    try {
      const data = {
        discount_code: code,
      }

      const response = await useApi(`/api/carts/${cartId}/discount-code`, {
        method: 'POST',
        body: data,
        watch: false,
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async saveAddress(cart, addressDifferent) {
    try {
      const { status } = useAuth()
      const data = {}
      if (Object.keys(cart.shipping_address).length !== 0)
        data.shipping_address = cart.shipping_address
      if (Object.keys(cart.billing_address).length !== 0)
        data.billing_address = cart.billing_address

      if (!addressDifferent)
        data.billing_address = undefined
      data.email = cart.email
      data.first_name = cart.first_name
      data.last_name = cart.last_name
      data.phone = cart.phone

      let response = null
      response = await useApi(`/api/carts/${cart.cart_id}/address`, {
        method: 'POST',
        body: data,
      })

      return response
    }
    catch (error) {
      return error
    }
  },
  async getCartWithCountry(id, country) {
    try {
      let response = null
      response = await useApi(`/api/carts/${id}?country=${country}`)

      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
  async getCart(id) {
    try {
      let response = null
      response = await useApi(`/api/carts/${id}`)
      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
  async updateCart(cart) {
    try {
      const formattedItems = cart.items.map((item) => {
        return { id: item.variant.id, amount: item.amount }
      })

      const response = await useApi(`/api/carts/${cart.cart_id}`, {
        method: 'POST',
        body: {
          live: cart.live,
          items: formattedItems,
        },
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async goToPayment(cart) {
    try {
      const response = await useApi(`/api/carts/${cart.cart_id}/payment`, {
        method: 'POST',
        body: {
          cart_id: cart.cart_id,
        },
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async goToRequest(cart) {
    try {
      const response = await useApi(`/api/carts/${cart.cart_id}/request`, {
        method: 'POST',
        body: {
          cart_id: cart.cart_id,
        },
      })
      return response
    }
    catch (error) {
      return error
    }
  },

}
