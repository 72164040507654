import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService'

export const useUserStore = defineStore('user', {
  persist: true,
  state: () => ({
    loginErrors: [],
    updateErrors: [],
    loginForm: {},
    user: {
      token: {},
      billing_address: {
        country: {},
      },
      shipping_address: {
        country: {},
      },
    },
    editedUser: {
      billing_address: {
        country: {},
      },
      shipping_address: {
        country: {},
      },
    },
  }),
  getters: {
    isLoggedIn() {
      return this.user?.token && Object.keys(this.user?.token).length !== 0
    },
  },
  actions: {
    async saveUser() {
      this.updateErrors = []
      const response = await AuthService.updateMe(this.editedUser, this.user.token.access_token)
      if (response.status.value === 'success') {
        this.user.id = response.data.value.id
        this.user.first_name = response.data.value.first_name
        this.user.last_name = response.data.value.last_name
        this.user.phonenumber = response.data.value.phone
        this.user.email = response.data.value.email
        this.user.language = response.data.value.language
        this.user.professional = response.data.value.role === 'b2b'
        this.user.role = response.data.value.role
        this.editedUser = { ...this.user }
      }
      else {
        this.updateErrors = [{ message: 'Update failed!' }]
      }
      return response.data.value
    },
    loadLocalStorage() {
      if (window.localStorage.getItem('user')) {
        this.user = JSON.parse(window.localStorage.getItem('user'))
        this.editedUser = { ...this.user }
      }

      if (window.localStorage.getItem('token')) {
        this.token = JSON.parse(window.localStorage.getItem('token'))
        axios.defaults.headers.common.Authorization = this.token.access_token
      }
      if (this.user?.token)
        axios.defaults.headers.common.Authorization = `Bearer ${this.user.token.access_token}`
    },
    async fetchMe() {
      const response = await AuthService.fetchMe(this.user.token.access_token)
      this.user = {
        id: response.data.value.id,
        first_name: response.data.value.first_name,
        last_name: response.data.value.last_name,
        phonenumber: response.data.value.phone,
        email: response.data.value.email,
        language: response.data.value.language,
        newsletter: true,
        billing_address: response.data.value.billing_address ?? {
          street: '',
          street_nr: '',
          bus: '',
          postcode: '',
          city: '',
        },
        shipping_address: response.data.value.shipping_address ?? {
          street: '',
          street_nr: '',
          bus: '',
          postcode: '',
          city: '',
        },
        created_at: '1633347989',
        professional: response.data.value.role === 'b2b',
        role: response.data.value.role,
        token: this.user.token,
      }
      if (response.billing_address) {
        this.user.billing_address.country = response.data.value.billing_address.country
        this.user.billing_address.street_nr = response.data.value.billing_address.street_number
        this.user.billing_address.bus = response.data.value.billing_address.bus_number
        this.user.billing_address.postcode = response.data.value.billing_address.zip_code
        this.user.billing_address.city = response.data.value.billing_address.city
      }
      if (response.shipping_address) {
        this.user.shipping_address.country = response.data.value.shipping_address.country
        this.user.shipping_address.street_nr = response.data.valueshipping_address.street_number
        this.user.shipping_address.bus = response.data.value.shipping_address.bus_number
        this.user.shipping_address.postcode = response.data.value.shipping_address.zip_code
        this.user.shipping_address.city = response.data.value.shipping_address.city
      }

      this.editedUser = { ...this.user }
    },
    async login() {
      // TODO TEMP LOGIN
      const response = await AuthService.login(this.loginForm)
      if (response.status.value === 'success') {
        this.user.token = {
          access_token: response.data.value.access_token,
          refresh_token: response.data.value.refresh_token,
          expires_in: response.data.value.expires_in,
          requested_at: new Date(),
        }
        axios.defaults.headers.common.Authorization = `Bearer ${this.user.token.access_token}`
        this.fetchMe()
      }
      return response
    },
    async loginWithData(data) {
      // TODO TEMP LOGIN
      const response = await AuthService.login(data)
      if (response.status.value === 'success') {
        this.user.token = {
          access_token: response.data.value.access_token,
          refresh_token: response.data.value.refresh_token,
          expires_in: response.data.value.expires_in,
          requested_at: new Date(),
        }
        axios.defaults.headers.common.Authorization = `Bearer ${this.user.token.access_token}`
        this.fetchMe()
      }
      return response.data.value
    },
    loginAsProf() {
      // TODO TEMP LOGIN
      this.user = {
        first_name: 'Robbe',
        last_name: 'Vaes',
        phonenumber: '+32496369539',
        email: 'robbe.vaes@appwise.be',
        address: {
          street: 'Saarbroekweg',
          street_nr: '51',
          bus: '',
          postcode: '3520',
          city: 'Zonhoven',
          country: 'be',
        },
        token: {},
        created_at: '1633347989',
        professional: true,
      }
      this.editedUser = this.user
    },
  },
})
