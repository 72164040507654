import axios from 'axios'

export default {
  async resetPassword({ passwordResetId, password, passwordRepeat, email }) {
    try {
      const response = await useApi('/auth/password-set', {
        token: passwordResetId,
        email,
        password,
        password_confirmation: passwordRepeat,
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async passwordForgot(email) {
    try {
      axios.defaults.baseURL = config.public.api.authUrl
      const response = await useApi('/auth/password-reset', {
        email,
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async updateMe(data, token) {
    try {
      const dataObject = {
        first_name: data.first_name,
        last_name: data.last_name,
        language: data.language,
        email: data.email,
        phone: data.phonenumber,
        newsletter: data.newsletter,
        shipping_address: {
          street: data.shipping_address.street,
          street_number: data.shipping_address.street_nr,
          zip_code: data.shipping_address.postcode,
          city: data.shipping_address.city,
          country: data.shipping_address.country,
          bus_number: data.shipping_address.bus,
        },
      }
      if (data.differentAddress) {
        dataObject.billing_address = {
          street: data.billing_address.street,
          street_number: data.billing_address.street_nr,
          zip_code: data.billing_address.postcode,
          city: data.billing_address.city,
          country: data.billing_address.country,
          bus_number: data.billing_address.bus,
        }
      }

      const response = await useApi('/auth/me/update', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: dataObject,
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async fetchMe(access_token) {
    try {
      axios.defaults.baseURL = config.public.api.authUrl
      const response = await useApi('/auth/me', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async login(data) {
    const config = useRuntimeConfig()
    try {
      const response = await useApi('/auth/token', {
        method: 'POST',
        body: {
          username: data.username,
          password: data.password,
          client_id: config.public.api.clientId,
          client_secret: config.public.api.clientSecret,
          grant_type: 'password',
        },
      })
      return response
    }
    catch (error) {
      return error
    }
  },
  async register(data) {
    try {
      axios.defaults.baseURL = config.public.api.authUrl
      const dataObject = {
        type: data.type ? 'b2c' : 'b2b',
        first_name: data.first_name,
        last_name: data.last_name,
        language: data.language,
        email: data.email,
        phone: data.phonenumber,
        newsletter: data.newsletter,
        password: data.password,
        company_name: data?.company_name,
        vat: data?.vat,
        password_confirmation: data.repeat_password,
        shipping_address: {
          street: data.street,
          street_number: data.street_nr,
          zip_code: data.postcode,
          city: data.city,
          country: data.country,
          bus_number: data.bus_number,
        },
      }
      if (data.differentAddress) {
        dataObject.billing_address = {
          street: data.invoice_street,
          street_number: data.invoice_street_nr,
          zip_code: data.invoice_postcode,
          city: data.invoice_city,
          country: data.invoice_country,
          bus_number: data.invoice_bus_number,
        }
      }
      const response = await useApi('/auth/register', {
        method: 'POST',
        body: dataObject,
      })
      return response
    }
    catch (error) {
      return error
    }
  },
}
