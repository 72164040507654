import crypto from 'node:crypto'
import type { UseFetchOptions } from 'nuxt/app'
import { defineStore } from 'pinia'
import CartService from '~/services/CartService'
import type { Variant } from '~/types/product.type'

export const useShoppingCartStore = defineStore('shoppingCart', () => {
  const shoppingCartId = useCookie<string>('shoppingCartId', {
    maxAge: 60 * 60 * 24 * 365, // 1 year
    default: () => crypto.randomUUID(),
  })

  const cart = ref<any | null>(null)
  const deliverTime = ref<any | null>(null)

  function newShoppingCart() {
    shoppingCartId.value = crypto.randomUUID()
    console.log('Generating new shopping cart ID', shoppingCartId.value)
    return getShoppingCart()
  }

  function getShoppingCart() {
    return useApi(`/api/carts/${shoppingCartId.value}`, {
      method: 'GET',
      watch: false,
      key: `shoppingCart-${shoppingCartId.value}`,
      onResponse({ response }) {
        // console.log('GOT CART', response._data)
        cart.value = response._data
      },
      onResponseError({ response }) {
        if (response.status === 400) {
          console.log('shopping cart expired, creating new one', shoppingCartId.value)
          newShoppingCart()
        }
      },
    })
  }

  function addProduct(variant_id: string, amount: number) {
    const productExists = cart.value?.items?.find((x: { variant: { id: any } }) => x.variant?.id === variant_id)
    if (productExists) {
      productExists.amount += amount
    }
    else {
      cart.value.items.push({
        variant: {
          id: variant_id,
        },
        amount,
      })
    }
    return updateCart()
  }

  function addFreeProduct(variant_id: string, amount: number) {
    cart.value.free_items.push({
      variant: {
        id: variant_id,
      },
      amount,
    })
    return updateCart()
  }

  function removeProduct(product_id: string) {
    cart.value.items = cart.value.items.filter((x: { variant: { id: string } }) => x.variant.id !== product_id)
    return updateCart()
  }

  function removeFreeProduct(product_id: string) {
    cart.value.free_items = cart.value.free_items.filter((x: { variant: { id: string } }) => x.variant.id !== product_id)
    return updateCart()
  }

  function updateRentCart(rentItems: { variant: Variant }[]) {
    const formattedItems = cart.value.items.map((item: { variant: { id: string }, amount: number }) => {
      return { id: item.variant.id, amount: item.amount }
    })
    const formattedFreeItems = cart.value.free_items.map((item: { variant: { id: string }, amount: number }) => {
      return { id: item.variant.id, amount: item.amount }
    })

    const formattedRentItems = rentItems
      .filter(item => item?.variant.id != null)
      .map((item) => {
        return { id: item.variant.id }
      })
    return useApi(`/api/carts/${shoppingCartId.value}`, {
      method: 'POST',
      body: {
        items: formattedItems,
        free_items: formattedFreeItems,
        rent_items: formattedRentItems,
      },
      watch: false,
      key: `shoppingCart-${shoppingCartId.value}`,
      onResponse({ response }) {
        cart.value = response._data
      },
    })
  }

  function updateCart() {
    const formattedItems = cart.value.items.map((item: { variant: { id: string }, amount: number }) => {
      return { id: item.variant.id, amount: item.amount }
    })
    const formattedFreeItems = cart.value.free_items.map((item: { variant: { id: string }, amount: number }) => {
      return { id: item.variant.id, amount: item.amount }
    })

    const formattedRentItems = cart.value.rent_items
      .filter((item: { variant: { id: string } }) => item?.variant.id != null)
      .map((item: { variant: { id: string } }) => {
        return { id: item.variant.id }
      })
    return useApi(`/api/carts/${shoppingCartId.value}`, {
      method: 'POST',
      body: {
        items: formattedItems,
        free_items: formattedFreeItems,
        rent_items: formattedRentItems,
      },
      watch: false,
      key: `shoppingCart-${shoppingCartId.value}`,
      onResponse({ response }) {
        cart.value = response._data
      },
    })
  }

  function updateBulkCart() {
    const { $i18n } = useNuxtApp()
    const { token } = useAuth()
    const config = useRuntimeConfig()
    const regionStore = useRegionStore()
    if (!config.public.api.baseUrl)
      throw new Error('Missing baseUrl in nuxt.config.js')

    const defaultHeaders = {
      Country: regionStore.currentRegion,
      Language: $i18n.locale,
    }

    const defaults = {
      baseURL: config.public.api.baseUrl ?? undefined,
      headers: token.value ? { ...defaultHeaders, Authorization: token.value } : defaultHeaders,
    }

    const formattedItems = cart.value?.items?.map((item: { variant: { id: string }, amount: number }) => {
      return { id: item.variant.id, amount: item.amount }
    })
    const formattedFreeItems = cart.value.free_items.map((item: { variant: { id: string }, amount: number }) => {
      return { id: item.variant.id, amount: item.amount }
    })
    return $fetch(`/api/carts/${shoppingCartId.value}`, {
      headers: defaults.headers,
      baseURL: defaults.baseURL,
      method: 'POST',
      body: {
        items: formattedItems,
        free_items: formattedFreeItems,
      },
      watch: false,
      key: `shoppingCart-${shoppingCartId.value}`,
      onResponse({ response }) {
        cart.value = response._data
      },
    })
  }

  // Invoice & billing addresses
  const billingInfo = ref<any>({})

  const authStore = useAuthStore()

  watch(() => authStore.me, (me) => {
    if (!me) {
      billingInfo.value = { billing_address: {}, shipping_address: {} }
      return
    }
    billingInfo.value = JSON.parse(JSON.stringify(me)) // me is standaard readonly, vandaar de spread
  }, { immediate: true })

  watch(billingInfo, (user) => {
    if (user.billing_address === null)
      user.billing_address = {}
  })

  function setBillingInfo() {
    const data: any = {}
    if (Object.keys(billingInfo.value.shipping_address).length !== 0)
      data.shipping_address = billingInfo.value.shipping_address
    if (Object.keys(billingInfo.value.billing_address).length !== 0)
      data.billing_address = billingInfo.value.billing_address

    if (!billingInfo.value.different_billing_address)
      data.billing_address = undefined
    data.email = billingInfo.value.email
    data.first_name = billingInfo.value.first_name
    data.last_name = billingInfo.value.last_name
    data.phone = billingInfo.value.phone

    if (!authStore.isB2B && billingInfo.value.vat)
      data.vat = billingInfo.value.vat

    return useApi(`/api/carts/${shoppingCartId.value}/address`, {
      method: 'POST',
      body: data,
      watch: false,
      key: `shoppingCart-${shoppingCartId.value}`,
      onResponse({ response }) {
        cart.value = response._data
      },
    })
  }

  async function orderCart() {
    const cartCountry = cart.value.shipping_address.country

    // Save cart to cookie for use in google analytics
    const lastCart = useLocalStorage('lastCart', {
      initialValue: {},
    })
    lastCart.value = cart.value
    if (['nl', 'be', 'lu'].includes(cartCountry)) {
      let url = ''
      if (!authStore.isB2B && billingInfo.value.vat_number)
        url = `/api/carts/${shoppingCartId.value}/request`

      else
        url = `/api/carts/${shoppingCartId.value}/payment`

      const { data, status, error } = await useApi<{ payment_url: string, [key: string]: any }>(url, {
        method: 'POST',
        watch: false,
      })
      console.log(data, status, error)
      if (status.value === 'success' && data.value?.payment_url)
        navigateTo(data.value?.payment_url, { external: true })
      else
        console.error('error while opening payment', data, error.value)
    }
    else {
      console.error('ILLEGAL COUNTRY', cartCountry)
      const { data } = await useApi(`/api/carts/${shoppingCartId.value}/request`, {
        method: 'POST',
        watch: false,
      })
      console.log('data', data)
    }
  }

  const currentStep = ref(1)

  const coupon = ref({
    reduction: 0,
  })

  async function bulkAddProducts(products: Array<{ product: number, amount: number }>) {
    products.forEach((product) => {
      const productExists = cart.value?.items?.find((x: { variant: { id: any } }) => x.variant?.id === product.product)
      if (productExists) {
        productExists.amount += product.amount
      }
      else {
        cart.value.items.push({
          variant: {
            id: product.product,
          },
          amount: product.amount,
        })
      }
    })
    return await updateBulkCart()
  }

  async function getDeliveryDays() {
    return useApi(`/api/settings/delivery.days`, {
      watch: false,
      key: `deliveryDays`,
      onResponse({ response }) {
        deliverTime.value = response._data
      },
    })
  }
  getDeliveryDays()

  const thresholds = computed(() => {
    const cartThresholds: {
      [key: string]: number
    }
    = cart.value?.free_thresholds ?? {}

    const thresholds = Object.entries(cartThresholds).map(([price, amount]) => {
      return {
        threshold: Number.parseInt(price),
        amount,
      }
    }).sort((a, b) => a.threshold - b.threshold)

    return thresholds
  })

  const currentThreshold = computed(() => {
    const currentPrice = cart.value?.total_price ?? 0
    const thresholdsLocal = [...thresholds.value].reverse()
    return thresholdsLocal.find((entry) => {
      return currentPrice >= entry.threshold
    })
  })

  const nextThreshold = computed(() => {
    if (!currentThreshold.value)
      return thresholds.value[0]
    const index = thresholds.value.findIndex(entry => entry.threshold === currentThreshold.value?.threshold)
    return index !== -1 ? thresholds.value[index + 1] : null
  })

  const maximumFreeProductsAmount = computed(() => {
    return currentThreshold.value?.amount ?? 0
  })

  const applyCoupon = async (code: string) => {
    const response = await CartService.applyCoupon(shoppingCartId.value, code) as any
    if (response.status.value === 'success')
      cart.value = response.data.value
    return response
  }

  const removeCoupon = async () => {
    const response = await CartService.removeCoupon(shoppingCartId.value) as any
    if (response.status.value === 'success')
      cart.value = response.data.value
    return response
  }

  return {
    shoppingCartId: readonly(shoppingCartId),
    shoppingCart: cart,
    billingInfo,
    currentStep,
    coupon,
    deliverTime,
    maximumFreeProductsAmount,
    thresholds,
    currentThreshold,
    nextThreshold,
    applyCoupon,
    removeCoupon,
    newShoppingCart,
    getShoppingCart,
    addProduct,
    addFreeProduct,
    removeProduct,
    removeFreeProduct,
    updateCart,
    setBillingInfo,
    updateRentCart,
    orderCart,
    bulkAddProducts,
  }
})
